import apiURL from './apiURL'
import handleFetch from './handleFetch.js'
import getAuthorization from './getAuthorization'

export const getStats = async (filter = {}) => {
  const query = String(new URLSearchParams(filter))
  const url = apiURL(`/stats?${query}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization(),
      'Content-Type': 'text/csv'
    }
  }
  return handleFetch(url, options)
}

export const getUpshotCsv = async (filter = {}) => {
  const query = String(new URLSearchParams(filter))
  const url = apiURL(`/stats/upshotexport?${query}`)
  const options = {
    method: 'GET',
    headers: {
      ...getAuthorization()
    }
  }
  return handleFetch(url, options)
}
